<template>
  <div class="content-wrap" :class="{ page: viewType == 'page' }">
    <a-result
      status="error"
      title="非常抱歉，系统在转换当前课件时遇到了点问题！"
      sub-title="如果确定当前上传的文件可以正常打开，可以点击问题反馈，将问题反馈给平台服务商"
    ></a-result>
  </div>
</template>
<script>
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    viewType: {
      type: String,
      default: 'dialog',
    },
  },
});
</script>
<style lang="less" scoped>
.content-wrap {
  height: calc(90vh - 162px);
  overflow: auto;
  &.page {
    height: calc(100vh - 262px);
  }
}
</style>
<template>
  <div class="content-wrap" :class="{ page: viewType == 'page' }">
    <div id="playArea2">
      <div class="water-marks" v-if="openViewWatermark">
        <div class="item" v-for="i in 60" :key="i">
          <OpenData type="userName" :openid="userInfo.realName" />(<OpenData
            type="departmentName"
            :openid="userInfo.departmentName"
          />)
        </div>
      </div>
      <iframe
        frameborder="0"
        marginheight="0"
        framespacing="0"
        marginwidth="0"
        width="100%"
        height="100%"
        :src="'/pdfview/web/viewer.html?v=2&canfull=no&file=' + filePath2"
      ></iframe>
    </div>
  </div>
</template>
<script>
import { defineComponent, reactive, toRefs } from "vue";
import ls from "@/utils/local-storage";
import OpenData from "@/components/OpenData.vue";
import { fullscreen } from "@/utils/tools.js";

export default defineComponent({
  components: {
    OpenData,
  },
  props: {
    openViewWatermark: {
      type: Boolean,
      default: false,
    },
    viewType: {
      type: String,
      default: "dialog",
    },
    filePath: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const state = reactive({
      filePath2:
        props.filePath.substr(0, props.filePath.lastIndexOf(".") + 1) + "pdf",
    });

    const receiveMessage = (e) => {
      if (e.data == "pdf-fullscreen") {
        fullscreen(document.querySelector("#playArea2"));
      } else if (e.data == "pdf-fullscreen-status") {
      }
    };

    window.addEventListener("message", receiveMessage, false);

    return {
      userInfo: ls.get("userInfo"),
      ...toRefs(state),
    };
  },
});
</script>
<style lang="less" scoped>
.textLayer {
  user-select: none; /* Standard syntax */
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
}
.content-wrap {
  height: calc(90vh - 162px);
  overflow: auto;
  font-size: 0;
  text-align: center;
  &.page {
    height: calc(100vh - 262px);
  }
}
#playArea2 {
  position: relative;
  height: 100%;
}
.water-marks {
  pointer-events: none;
  z-index: 999;
  position: absolute;
  right: 0;
  left: 0;
  top: 42px;
  bottom: 0;
  overflow: hidden;
  .item {
    float: left;
    transform: rotate(-20deg);
    margin: 50px 50px 250px 50px;
    font-size: 18px;
    line-height: 40px;
    color: rgba(170, 170, 170, 0.2);
    pointer-events: none;
  }
}
</style>

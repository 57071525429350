<template>
  <div class="content-wrap" :class="{ page: viewType == 'page' }">
    <div class="preview-video-box">
      <div class="water-mark" v-if="openViewWatermark">
        <OpenData type="userName" :openid="userInfo.realName" />(<OpenData
          type="departmentName"
          :openid="userInfo.departmentName"
        />)
      </div>
      <ali-player
        class="preview-video"
        width="100%"
        height="100%"
        :useH5Prism="true"
        x5_type="H5"
        :skinLayout="skinLayout"
        :source="filePath"
        @requestFullScreen="fullScreenHandler"
      />
    </div>
  </div>
</template>
<script>
import { defineComponent } from "vue";
import ls from "@/utils/local-storage";
import OpenData from "@/components/OpenData.vue";
import VueAliplayer from "@/components/course/vue-aliplayer";

export default defineComponent({
  components: {
    OpenData,
    "ali-player": VueAliplayer,
  },
  props: {
    openViewWatermark: {
      type: Boolean,
      default: false,
    },
    viewType: {
      type: String,
      default: "dialog",
    },
    filePath: {
      type: String,
      required: true,
    },
  },
  setup() {
    const fullScreenHandler = () => {
      let water = document.querySelector(".preview-video-box .water-mark");
      let wrap = document.querySelector(".preview-video-box .prism-player");
      let water2 = water.cloneNode(true);
      wrap.appendChild(water2);
    };

    return {
      userInfo: ls.get("userInfo"),
      fullScreenHandler,
      skinLayout: [
        { name: "bigPlayButton", align: "blabs", x: 30, y: 80 },
        {
          name: "controlBar",
          align: "blabs",
          x: 0,
          y: 0,
          children: [
            { name: "progress", align: "blabs", x: 0, y: 44 },
            { name: "playButton", align: "tl", x: 15, y: 12 },
            { name: "timeDisplay", align: "tl", x: 10, y: 7 },
            {
              name: "fullScreenButton",
              align: "tr",
              x: 10,
              y: 12,
            },
            { name: "setting", align: "tr", x: 15, y: 12 },
            { name: "volume", align: "tr", x: 5, y: 10 },
          ],
        },
      ],
    };
  },
});
</script>
<style lang="less" scoped>
.content-wrap {
  height: calc(90vh - 162px);
  overflow: auto;
  text-align: center;
  &.page {
    height: calc(100vh - 262px);
  }
  .preview-video {
    width: 960px;
    height: 606px;
  }
}
.preview-video-box {
  width: 960px;
  height: 606px;
  position: relative;
  margin: 0 auto;
  .water-mark {
    pointer-events: none;
    z-index: 999;
    position: absolute;
    right: 20px;
    top: 20px;
    font-size: 24px;
    color: rgba(170, 170, 170, 0.4);
    line-height: 40px;
  }
}
</style>

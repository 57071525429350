<template>
  <div class="content-wrap" :class="{ page: viewType == 'page' }">
    <a-result
      status="warning"
      title="当前课件正在转换中，转换完成后方可在线观看"
      sub-title="课件转换通常需要30分钟左右的时间，请耐心等待"
    ></a-result>
  </div>
</template>
<script>
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    viewType: {
      type: String,
      default: 'dialog',
    },
  },
});
</script>
<style lang="less" scoped>
.content-wrap {
  height: calc(90vh - 162px);
  overflow: auto;
  &.page {
    height: calc(100vh - 262px);
  }
  .tips {
    font-size: 14px;
    color: #666;
  }
}
</style>